<template>
  <div
    class="article"
    :class="{ removePadding: $route.query.comment === 'show' }"
  >
    <ModalShare @registeredModal="modalShare = $event" />
    <ModalLogin @registeredModal="modalLogin = $event" />
    <ModalReportComment
      @registeredModal="modalReportComment = $event"
      :commentId="comment_id"
      @event="reportComment"
    />
    <ArticlePageTopAds />
    <div
      data-rfp-adspot-id="OTI5OjE5NDEx"
      class="freakoutAds"
      style="display: none"
    ></div>
    <div id="div-gpt-ad-1692873102878-0" class="gpt-ads"></div>
    <div id="div-gpt-ad-1692873590923-0" class="gpt-ads"></div>
    <div id="div-gpt-ad-1701941519832-0" class="gpt-ads"></div>
    <!-- ADS END -->
    <div class="container">
      <div class="primary">
        <div
          class="article-reguler"
          :class="{ setHeight: $route.query.comment === 'show' }"
        >
          <div class="article-reguler__headline">
            <ul
              class="breadcrumbs"
              v-if="response.data && response.data.breadcrumbs.length"
            >
              <li
                v-for="(breadcrumb, index) in response.data.breadcrumbs"
                :key="`breadcrumb-${breadcrumb.name}-${index}`"
                class="breadcrumb-item"
              >
                <Link
                  :to="
                    breadcrumb.type !== 'interest'
                      ? breadcrumb.slug
                      : response.data.breadcrumbs.length > 2
                      ? `${response.data.breadcrumbs[1].slug}${breadcrumb.slug}`
                      : '/news'
                  "
                >
                  {{ breadcrumb.name.toLowerCase() }}
                </Link>
              </li>
            </ul>
            <h1 class="title_">
              {{ response.data.title }}
            </h1>
            <div class="date-and-share">
              <div class="date">
                <p class="text">
                  {{
                    `
                    ${$moment(response.data.publishDate)
                      .utc()
                      .format("Do MMMM YYYY HH:MM")} WIB`
                  }}
                </p>
              </div>
              <div class="share-wrapper">
                <a
                  :href="setLink('whatsapp')"
                  target="_blank"
                  rel="noopener"
                  class="sosmed-wrapper share-artikel"
                >
                  <Icons name="whatsapp" />
                </a>
                <a
                  :href="setLink('twitter')"
                  target="_blank"
                  rel="noopener"
                  class="sosmed-wrapper share-artikel"
                >
                  <Icons name="logo-x-blue" color="#000000" />
                </a>
                <a
                  :href="setLink('facebook')"
                  target="_blank"
                  rel="noopener"
                  class="sosmed-wrapper share-artikel"
                >
                  <Icons name="facebook" />
                </a>
                <div
                  class="sosmed-wrapper share-artikel copy-link"
                  @click="copyLink()"
                >
                  Copy Link
                </div>
              </div>
            </div>
          </div>
          <div class="article-reguler__thumbnail">
            <ImageResponsive
              :imageUrl="
                response.data && response.data.thumbnail
                  ? response.data.thumbnail.large
                  : ''
              "
              :fromUrl="true"
              width="877"
              height="489"
              class="img-fluid w-100"
              alt="Narasi TV"
            />
            <p
              class="caption"
              v-if="
                response.data.thumbnail &&
                response.data.thumbnail.caption &&
                response.data.thumbnail.caption != 'null'
              "
            >
              {{ response.data.thumbnail.caption }}
            </p>
          </div>
          <div class="article-reguler__detail">
            <div class="penulis-editor">
              <p class="penulis" v-if="response.data.author">
                Penulis: <span>{{ response.data.author }}</span>
              </p>
              <p class="editor">
                Editor: <span>{{ response.data.createdBy }}</span>
              </p>
            </div>
            <script
              async
              src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            ></script>
            <div id="gpt-passback">
              <script>
                window.googletag = window.googletag || { cmd: [] };
                googletag.cmd.push(function () {
                  googletag
                    .defineSlot(
                      "/22849909437/artikel-page-banner/artikel-below-thumbnail",
                      [
                        [320, 100],
                        [300, 250],
                        [320, 50],
                      ],
                      "gpt-passback"
                    )
                    .addService(googletag.pubads());
                  googletag.enableServices();
                  googletag.display("gpt-passback");
                });
              </script>
            </div>
            <div
              v-if="
                response.data.articlePart &&
                response.data.articlePart.length > 0
              "
              class="article-content"
            >
              <p
                class="text desc-article"
                v-for="(article, index) in page !== 'full'
                  ? response.data.articlePart.slice(0, 1)
                  : response.data.articlePart"
                :key="`desc-article-${index}`"
                :id="`part-${index + 1}`"
                v-html="
                  article.description
                    .replace(/<p><br[\/]?><[\/]?p>/g, '')
                    .replace(/<p>&nbsp;<[\/]?p>/g, '')
                "
              ></p>
              <div
                class="shadow-read-more"
                v-if="
                  response.data.articlePart &&
                  response.data.articlePart.length > 1 &&
                  !$route.query.page
                "
              ></div>
            </div>
            <div v-else class="article-content">
              <p
                class="text desc-article"
                v-html="
                  response.data.content
                    .replace(/<p><br[\/]?><[\/]?p>/g, '')
                    .replace(/<p>&nbsp;<[\/]?p>/g, '')
                "
              ></p>
            </div>
            <Link
              v-if="
                response.data.articlePart &&
                response.data.articlePart.length > 1 &&
                !$route.query.page
              "
              :to="{
                name: 'read-channel-slug',
                params: {
                  channel: $route.params.channel,
                  slug: $route.params.slug,
                },
                query: {
                  page: 'full',
                },
              }"
              class="read-more-article"
            >
              Baca Selengkapnya <Icons name="arrow-bottom" color="#4a25aa" />
            </Link>

            <div
              class="etalase-product"
              v-show="
                response.data.productSnippet &&
                response.data.productSnippet.length > 0
              "
            >
              <div class="list-etalase-product">
                <div
                  v-swiper:mySwiperEtalaseProduct="
                    swiperOptions.etalase_product
                  "
                >
                  <div class="swiper-wrapper">
                    <!-- <ShimmerCardHome
                      v-show="etalase_product.loading"
                      v-for="(item, index) in 4"
                      :key="`etalase-product-shimmer-${index}`"
                      class="swiper-slide"
                    /> -->
                    <CardEtalase
                      class="swiper-slide"
                      v-for="(item, index) in response.data.productSnippet"
                      :key="`etalase-product-${index}`"
                      :image="item.imageUrl"
                      :title="item.title"
                      :price="item.price"
                      :btnName="item.btnName"
                      :to="item.linkProduct"
                    />
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
                <div class="shadow-arrow shadow-left prev-etalase-product">
                  <Icons name="arrow-left" class="arrow prev" />
                </div>
                <div class="shadow-arrow shadow-right next-etalase-product">
                  <Icons name="arrow-right" class="arrow next" />
                </div>
              </div>
            </div>
            <div
              class="tags"
              v-if="response.data.tags && response.data.tags.length"
            >
              <p class="text">Topik:</p>
              <div class="tag-wrapper">
                <Link
                  v-for="(item, index) in response.data.tags"
                  :key="`tags-${index}`"
                  :to="{
                    name: 'tags-slug',
                    params: {
                      slug: item.slug,
                    },
                  }"
                  class="tag"
                  >{{ item.topic }}</Link
                >
              </div>
            </div>
            <div class="komentar-icons" @click="goto('komentar')">
              <Icons name="komentar" />
            </div>
          </div>
          <div
            class="article-reguler__artikel-terkait"
            v-show="artikel_terkait.items && artikel_terkait.items.length > 0"
          >
            <div class="title-wrapper">
              <TitleSection title="ARTIKEL TERKAIT" />
            </div>
            <div class="list-card-artikel-terkait">
              <ShimmerCardHome
                v-show="artikel_terkait.loading"
                v-for="(item, index) in 4"
                :key="`artikel_terkait-shimmer-${index}`"
                class="swiper-slide"
              />
              <div
                data-rfp-adspot-id="OTY1OjE5NDEw"
                style="display: none"
              ></div>
              <CardTwo
                v-show="!artikel_terkait.loading"
                v-for="(item, index) in artikel_terkait.items"
                :key="`artikel-terkait-${index}`"
                :image="
                  item.thumbnail && item.thumbnail.medium
                    ? item.thumbnail.medium
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :duration="item.timeVideo ? item.timeVideo : '00:00'"
                :channel="item.channel.title"
                :channelSlug="item.channel.slug"
                :programSlug="
                  item.channel.program ? item.channel.program.slug : ''
                "
                :category="item.category ? item.category.title : 'category'"
                :categorySlug="item.category ? item.category.slug : 'category'"
                :title="item.title"
                :isText="item.isText"
                :isAdvertorial="item.isAdvertorial"
                :withDate="false"
                :withCategory="false"
                :customTitle="true"
                :date="item.publishDate"
                :mobileCardDouble="true"
                :to="
                  item.isText
                    ? {
                        name: `read-channel-slug`,
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                      }
                "
                class="swiper-slide"
              />
            </div>
          </div>
          <div
            class="article-reguler__video-terkait"
            v-show="video_terkait.items && video_terkait.items.length > 0"
          >
            <div class="title-wrapper">
              <TitleSection title="VIDEO TERKAIT" />
            </div>
            <div class="list-card-video-terkait">
              <div v-swiper:mySwiperVideoTerkait="swiperOptions.video_terkait">
                <div class="swiper-wrapper">
                  <ShimmerCardHome
                    v-show="video_terkait.loading"
                    v-for="(item, index) in 4"
                    :key="`video-terkait-shimmer-${index}`"
                    class="swiper-slide"
                  />
                  <CardTwo
                    v-show="!video_terkait.loading"
                    v-for="(item, index) in video_terkait.items"
                    :to="
                      item.isText
                        ? {
                            name: `read-channel-slug`,
                            params: {
                              channel: item.channel.slug,
                              slug: item.slug,
                            },
                            query: {
                              ref: 'video-terkait',
                            },
                          }
                        : {
                            name: 'video-channel-slug',
                            params: {
                              channel: item.channel.slug,
                              slug: item.slug,
                            },
                            query: {
                              ref: 'video-terkait',
                            },
                          }
                    "
                    :key="`video-terkait-${index}`"
                    :image="
                      item.thumbnail && item.thumbnail.medium
                        ? item.thumbnail.medium
                        : 'https://narasi.tv/storage/images/dummy.png'
                    "
                    :imageFromUrl="true"
                    :duration="item.timeVideo ? item.timeVideo : '00:00'"
                    :channel="item.channel.title"
                    :channelSlug="item.channel.slug"
                    :programSlug="
                      item.channel.program ? item.channel.program.slug : ''
                    "
                    :category="item.category ? item.category.title : 'category'"
                    :categorySlug="
                      item.category ? item.category.slug : 'category'
                    "
                    :title="item.title"
                    :isText="item.isText"
                    :isAdvertorial="item.isAdvertorial"
                    :withCategory="false"
                    :customTitle="true"
                    class="swiper-slide"
                  />
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <div class="shadow-arrow shadow-left prev-video-terkait">
                <Icons name="arrow-left" class="arrow prev" />
              </div>
              <div class="shadow-arrow shadow-right next-video-terkait">
                <Icons name="arrow-right" class="arrow next" />
              </div>
            </div>
          </div>
          <!-- ADS START -->
          <AdsHorizontalFixed
            class="mb-3"
            width="300px"
            height="250px"
            id="placement_before_video_detail_academy"
          ></AdsHorizontalFixed>
          <!-- ADS END -->
          <div class="article-reguler__narasi-academy">
            <div class="title-wrapper">
              <TitleSection title="NARASI ACADEMY" />
              <!-- <Link
                :to="{
                  name: 'academy',
                }"
                class="lihat-semua"
                >Lihat Semua</Link
              > -->
            </div>
            <div class="list-card-narasi-academy">
              <div v-swiper:mySwiperIkutiKelas="swiperOptions.ikuti_kelas">
                <div class="swiper-wrapper">
                  <ShimmerListCard v-if="ikutiKelas.loading" />
                  <CardTwentyThree
                    class="swiper-slide"
                    v-for="(card, index) in ikutiKelas.items"
                    v-show="ikutiKelas.items.length > 0"
                    :key="'card-ikuti-kelas' + index"
                    :image="
                      card.thumbnail && card.thumbnail.large
                        ? card.thumbnail.large
                        : 'https://narasi.tv/storage/images/dummy.png'
                    "
                    :imageFromUrl="true"
                    :categories="card.category"
                    :classType="card.classType"
                    :title="card.title"
                    :slug="card.slug"
                    :description="card.description"
                    :datetime="card.startDate"
                    :isSoon="card.isSoon"
                    :price="card.price"
                    :discountPrice="card.discountPrice"
                    :discount="card.discount"
                    :to="{
                      name: 'academy-detail-slug',
                      params: {
                        slug: card.slug,
                      },
                    }"
                    :startEvent="card.startDate"
                    :endEvent="card.endDate"
                    :startRegis="card.startRegistrationDate"
                    :endRegis="card.endRegistrationDate"
                  />
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <div
                class="shadow-arrow shadow-left prev-ikuti_kelas"
                v-show="ikutiKelas.items.length > 3"
              >
                <Icons
                  name="arrow-left"
                  class="arrow prev"
                  v-show="ikutiKelas.items.length > 3"
                />
              </div>
              <div
                class="shadow-arrow shadow-right next-ikuti_kelas"
                v-show="ikutiKelas.items.length > 3"
              >
                <Icons
                  name="arrow-right"
                  class="arrow next"
                  v-show="ikutiKelas.items.length > 3"
                />
              </div>
            </div>
          </div>
          <NextArticle
            :article="
              artikel_terkait.items.length > 0 ? artikel_terkait.items[0] : null
            "
          />
        </div>
        <div class="playlist-article-reguler">
          <div class="playlist-article-reguler__populer">
            <!-- ADS START -->
            <AdsHorizontalFixed
              class="pb-3"
              width="300px"
              height="250px"
              id="placement_sidebase_article_detail"
            ></AdsHorizontalFixed>
            <!-- ADS END -->
            <div class="header">
              <TitleSection
                title="TERPOPULER"
                size="medium"
                class="header__title"
              />
            </div>
            <div class="body">
              <transition-group
                name="list"
                tag="div"
                class="list-video-populer"
              >
                <ShimmerCardHomeLandscapeSmall
                  v-for="item in 5"
                  :key="`populer-shimmer${item}`"
                  v-show="populer.loading"
                />
                <CardEight
                  v-show="!populer.loading"
                  v-for="item in populer.items"
                  :key="`populer-${item.id}`"
                  :image="
                    item.thumbnail
                      ? item.thumbnail.large
                      : 'https://narasi.tv/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :duration="item.timeVideo ? item.timeVideo : '00:00'"
                  :category="item.category ? item.category.title : ''"
                  :categorySlug="item.category ? item.category.slug : ''"
                  :channel="item.channel ? item.channel.title : ''"
                  :channelSlug="item.channel.slug"
                  :programSlug="
                    item.channel.program ? item.channel.program.slug : ''
                  "
                  :title="item.title"
                  :isText="item.isText"
                  :isAdvertorial="item.isAdvertorial"
                  :to="
                    item.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'terpopuler-detail',
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'terpopuler-detail',
                          },
                        }
                  "
                  class="card-video-populer"
                />
              </transition-group>
              <div
                class="button-show-more"
                :class="{ 'mt-5': populer.loadingShowMore }"
              >
                <span
                  v-if="
                    !populer.loadingShowMore &&
                    populer.items.length < populer.totalItem
                  "
                  @click="nextArticle"
                  >LIHAT LEBIH BANYAK
                </span>
                <div
                  v-if="populer.loadingShowMore"
                  class="dot-spin m-auto"
                ></div>
              </div>
            </div>
          </div>
          <AdsHorizontalFixedProps
            class="mt-3"
            width="315px"
            height="290px"
            id="props_placement_before_populer"
          ></AdsHorizontalFixedProps>
          <ArticlePageStickyAds />
        </div>
      </div>
      <div class="secondary">
        <div class="article-reguler">
          <div class="article-reguler__comment" id="komentar">
            <div class="title-wrapper header">
              <TitleSection title="KOMENTAR" size="medium" />
              <div class="dropdown filter">
                <div
                  class="dropdown-toggle"
                  id="dropdownMenuSortComment"
                  data-bs-toggle="dropdown"
                >
                  <p class="text">{{ sortLabel }}</p>
                  <div class="arrow">
                    <Icons name="arrow-bottom" />
                  </div>
                </div>
                <ul class="dropdown-menu">
                  <li
                    v-for="(sortItem, indexSort) in sortComment"
                    :key="'sort-' + indexSort"
                    @click="
                      getSortCommentBy(sortItem.param),
                        (sortLabel = sortItem.label)
                    "
                  >
                    <div class="dropdown-item">{{ sortItem.label }}</div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="comments">
              <div class="type-comment">
                <div class="type-comment__profile-image">
                  <ImageResponsive
                    v-if="
                      $store.state.auth.user && $store.state.auth.user.picture
                    "
                    :imageUrl="$store.state.auth.user.picture"
                    :fromUrl="true"
                  />
                  <ProfilePicture
                    v-else
                    :initial="
                      $store.state.auth.user && $store.state.auth.user.fullname
                        ? $store.state.auth.user.fullname.substr(0, 1)
                        : ''
                    "
                  />
                </div>
                <div class="type-comment__input">
                  <textarea
                    class="form-control"
                    aria-label="With textarea"
                    placeholder="Tulis Komentar..."
                    ref="type_comment_mobile"
                    :rows="btnSend ? 4 : 1"
                    @focus="
                      $store.state.auth.loggedIn
                        ? (btnSend = true)
                        : modalLogin.show()
                    "
                    :maxlength="limitCharComment"
                    v-model="comment"
                  ></textarea>
                  <p class="disclaimer" v-show="btnSend">
                    Anda sepenuhnya bertanggung jawab atas komentar yang
                    diberikan, hindari ujaran melanggar hukum.
                  </p>
                  <div class="btn-wrapper" v-show="btnSend">
                    <div class="btn-cancel" @click="btnSend = false">BATAL</div>
                    <div class="btn-send" @click="postComment('main')">
                      KIRIM
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="comments.loading" class="p-5">
                <div class="dot-spin m-auto"></div>
              </div>
              <div
                class="comments__no"
                v-if="comments.items.length === 0 && !comments.loading"
              >
                <Icons name="no-comment" class="no-comment-icon" />
                <p class="desc">Belum ada komentar</p>
                <p class="sub-desc">
                  Jadilah yang pertama mengirimkan komentar untuk bertukar
                  gagasan dengan pengguna lainnya
                </p>
              </div>
              <div
                v-show="comments && comments.items.length > 0"
                class="comments__card"
                v-for="(item, index_comment) in $store.state.videos.comments
                  .items"
                :key="`index_comment-${index_comment}`"
              >
                <div class="comments__card__profile-image">
                  <ImageResponsive
                    v-if="item.commenter && item.commenter.picture"
                    :imageUrl="item.commenter.picture"
                    :fromUrl="true"
                  />
                  <ProfilePicture
                    v-else
                    :initial="
                      item.commenter && item.commenter.fullname
                        ? item.commenter.fullname.substr(0, 1)
                        : ''
                    "
                  />
                </div>
                <div class="comments__card__detail">
                  <div class="comment-header">
                    <div class="comment-header__copy">
                      <h2 class="comment-header__copy__username">
                        {{ item.commenter.fullname }}
                      </h2>
                      <p class="comment-header__copy__time">
                        {{ item.createdAt }}
                      </p>
                    </div>
                    <div
                      class="comment-header__dots"
                      @click="
                        $store.state.auth.loggedIn
                          ? [modalReportComment.show(), setCommentId(item.id)]
                          : modalLogin.show()
                      "
                    >
                      <Icons name="dots-option" class="dots" />
                    </div>
                  </div>
                  <div class="comment-body">
                    <p
                      class="comment-body__desc"
                      id="desc"
                      v-html="item.comment"
                    ></p>
                  </div>
                  <div
                    class="comment-footer"
                    :class="item.children.length > 0 ? 'addMargin' : ''"
                  >
                    <div
                      class="comment-footer__like"
                      @click="
                        toggleLike(
                          item,
                          index_comment,
                          response.data.slug,
                          'comment'
                        )
                      "
                    >
                      <Icons
                        name="like"
                        :color="item.isLiked ? '#4A25AA' : '#616161'"
                      />
                      <p class="total-like">
                        {{ item.likes == 0 ? "suka" : item.likes + " suka" }}
                      </p>
                    </div>
                    <div
                      class="comment-footer__reply"
                      @click="replyComment(index_comment, item.id)"
                    >
                      <Icons name="reply" />
                      <p class="text">Balas Komentar</p>
                    </div>
                  </div>
                  <!-- REPLY COMMENT START -->
                  <div
                    class="type-comment sub-type-comment"
                    :class="item.children.length > 0 ? 'addPadding' : ''"
                    v-show="index_comment === subComment.indexComment"
                  >
                    <div class="type-comment__profile-image">
                      <ImageResponsive
                        v-if="
                          $store.state.auth.user &&
                          $store.state.auth.user.picture
                        "
                        :imageUrl="$store.state.auth.user.picture"
                        :fromUrl="true"
                      />
                      <ProfilePicture
                        v-else
                        :initial="
                          $store.state.auth.user &&
                          $store.state.auth.user.fullname
                            ? $store.state.auth.user.fullname.substr(0, 1)
                            : ''
                        "
                      />
                    </div>
                    <div class="type-comment__input">
                      <textarea
                        class="form-control"
                        aria-label="With textarea"
                        placeholder="Tulis Komentar..."
                        ref="type_comment_mobile"
                        rows="1"
                        maxlength="400"
                        v-model="subComment.mobile.value"
                      />
                      <p class="disclaimer">
                        Anda sepenuhnya bertanggung jawab atas komentar yang
                        diberikan, hindari ujaran melanggar hukum.
                      </p>
                      <div class="btn-wrapper">
                        <div
                          class="btn-cancel"
                          @click="replyComment(index_comment, item.id)"
                        >
                          BATAL
                        </div>
                        <div class="btn-send" @click="postComment('subMobile')">
                          KIRIM
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- REPLY COMMENT END -->
                  <!-- SUB COMMENT START -->
                  <transition-group name="list">
                    <div
                      v-show="index_comment === subComment.indexShowSubComment"
                      class="comments__card"
                      v-for="(subItem, index_sub_comment) in item.children"
                      :key="'subComment ' + index_sub_comment"
                    >
                      <div class="comments__card__profile-image">
                        <ImageResponsive
                          v-if="subItem.commenter && subItem.commenter.picture"
                          :imageUrl="subItem.commenter.picture"
                          :fromUrl="true"
                        />
                        <ProfilePicture
                          v-else
                          :initial="
                            subItem.commenter && subItem.commenter.fullname
                              ? subItem.commenter.fullname.substr(0, 1)
                              : ''
                          "
                        />
                      </div>
                      <div class="comments__card__detail">
                        <div class="comment-header">
                          <div class="comment-header__copy">
                            <h2 class="comment-header__copy__username">
                              {{ subItem.commenter.fullname }}
                            </h2>
                            <p class="comment-header__copy__time">
                              {{ subItem.createdAt }}
                            </p>
                          </div>
                          <div
                            class="comment-header__dots"
                            @click="
                              $store.state.auth.loggedIn
                                ? [
                                    modalReportComment.show(),
                                    setCommentId(subItem.id),
                                  ]
                                : modalLogin.show()
                            "
                          >
                            <Icons name="dots-option" class="dots" />
                          </div>
                        </div>
                        <div class="comment-body">
                          <p
                            class="comment-body__desc"
                            v-html="subItem.comment"
                          ></p>
                        </div>
                        <div class="comment-footer">
                          <div
                            class="comment-footer__like"
                            @click="
                              toggleLike(
                                subItem,
                                index_comment,
                                response.data.slug,
                                'sub-comment',
                                index_sub_comment
                              )
                            "
                          >
                            <Icons
                              name="like"
                              :color="subItem.isLiked ? '#4A25AA' : '#616161'"
                            />
                            <p class="total-like">
                              {{
                                subItem.likes == 0
                                  ? "suka"
                                  : subItem.likes + " suka"
                              }}
                            </p>
                          </div>
                          <!-- <div class="comment-footer__reply">
                          <p class="text">BALAS</p>
                        </div> -->
                        </div>
                      </div>
                    </div>
                  </transition-group>
                  <div
                    class="show-more-reply"
                    v-show="item.children.length > 0"
                    @click="showSubComment(index_comment)"
                  >
                    <Icons
                      name="arrow-bottom"
                      color="#616161"
                      :class="{
                        rotate:
                          item.children.length ===
                            comments.items[index_comment].children.length &&
                          index_comment === subComment.indexShowSubComment,
                      }"
                    />
                    <p>
                      {{ getReplyText(item.children.length, index_comment) }}
                    </p>
                  </div>
                  <!-- SUB COMMENT END -->
                </div>
              </div>
            </div>
            <div
              v-if="
                !comments.loading &&
                !loadingShowMoreComment &&
                comments.items.length != comments.pagination.total
              "
              class="show-more-comment"
              @click="loadMoreComments()"
            >
              Lihat Komentar Lainnya
            </div>
            <div v-if="loadingShowMoreComment" class="dot-spin-wrapper">
              <div class="dot-spin m-auto"></div>
            </div>
          </div>
        </div>
      </div>
      <ArticlePageBottomAds />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import ModalLogin from "/components/modal/Login.vue";
import ModalShare from "/components/modal/Share.vue";
import ModalReportComment from "/components/modal/ReportComment.vue";
import CardTwo from "/components/cards/CardTwo.vue";
import CardEight from "/components/cards/CardEight.vue";
import CardTwentyThree from "/components/cards/CardTwentyThree.vue";
import CardEtalase from "/components/cards/CardEtalase.vue";
import ArticlePageTopAds from "/components/ads/artikelTeks/ArticlePageTopAds.vue";
import ArticlePageBottomAds from "@/components/ads/artikelTeks/ArticlePageBottomAds.vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import config from "/config";
import { mapGetters } from "vuex";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";
import ArticlePageStickyAds from "@/components/ads/artikelTeks/ArticlePageStickyAds.vue";
import ShimmerArticleText from "@/components/shimmer/ArticleText";
import ShimmerCardHome from "@/components/shimmer/CardHome";
import ShimmerListCard from "@/components/shimmer/ListCard";
import ShimmerCardHomeLandscapeSmall from "@/components/shimmer/CardHomeLandscapeSmall";
import AdsHorizontalFixed from "@/components/ads/HorizontalFixed";
import AdsHorizontalFixedProps from "@/components/ads/HorizontalFixedProps";
import TitleSection from "@/components/TitleSection";
import ProfilePicture from "@/components/ProfilePicture";
import JadwalImsak from "@/components/ramadan/JadwalImsak.vue";
import NextArticle from "@/components/NextArticle.vue";

Vue.component("paginate", Paginate);
export default {
  components: {
    ProfilePicture,
    AdsHorizontalFixedProps,
    ShimmerCardHomeLandscapeSmall,
    ShimmerListCard,
    ShimmerCardHome,
    ShimmerArticleText,
    Link,
    Icons,
    ImageResponsive,
    ModalLogin,
    ModalShare,
    ModalReportComment,
    CardTwo,
    CardEight,
    CardTwentyThree,
    CardEtalase,
    ArticlePageTopAds,
    ArticlePageBottomAds,
    ArticlePageStickyAds,
    TitleSection,
    AdsHorizontalFixed,
    JadwalImsak,
    NextArticle,
  },
  async asyncData({ app, params, query, store, route, error, redirect }) {
    try {
      store.commit("config/setLoading", true);

      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}articles/${params.slug}`
      );

      if (route.query.page && route.query.page !== "full") {
        redirect(
          301,
          query.ref
            ? `/read/${params.channel}/${params.slug}?ref=${query.ref}`
            : `/read/${params.channel}/${params.slug}`
        );
      }

      if (!response.data.isText) {
        redirect(
          301,
          query.ref
            ? `/video/${response.data.channel.slug}/${response.data.slug}?ref=${query.ref}`
            : `/video/${response.data.channel.slug}/${response.data.slug}`
        );
      }

      if (response.status === 301) {
        redirect(
          301,
          query.ref
            ? `/read/${params.channel}/${response.redirect}?ref=${query.ref}`
            : `/read/${params.channel}/${response.redirect}`
        );
      }

      if (response.data.isVideo || response.data.isPodcast) {
        return error({ statusCode: 404, message: "Page not found" });
      }

      if (response.data.channel.slug !== params.channel) {
        redirect(
          301,
          query.ref
            ? `/read/${response.data.channel.slug}/${response.data.slug}?ref=${query.ref}`
            : `/read/${response.data.channel.slug}/${response.data.slug}`
        );
      }

      if (response.data.isAdvertorial) {
        redirect(
          301,
          query.ref
            ? `/read/advertorial/${response.data.channel.slug}/${response.data.slug}?ref=${query.ref}`
            : `/read/advertorial/${response.data.channel.slug}/${response.data.slug}`
        );
      }

      store.commit("seoWithAmp/SET_SEO", {
        title: response.data.title,
        desc: response.data.short ? response.data.short : response.data.content,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "https://narasi.tv/storage/images/dummy.png",
        url: `${config.BASE_URL}${route.path}`,
        path: `${config.BASE_URL}${route.path}`,
        ampPath: `${config.BASE_URL}/amp${route.path}`,
      });

      store.commit("config/setLoading", false);

      return { response };
    } catch (err) {
      store.commit("config/setLoading", false);
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  data() {
    return {
      showMoreReply: false,
      btnSend: false,
      url: "",
      flipArrow: "true",
      limit: 8,
      limitCommentMobile: 3,
      limitCommentDesktop: 10,
      limitCharComment: 400,
      loadingShowMoreComment: false,
      comment: "",
      comment_id: null,
      page: this.$route.query.page ? this.$route.query.page : "",
      screenSize: null,
      subComment: {
        commentId: "",
        indexComment: null,
        indexShowSubComment: null,
        desktop: {
          value: "",
        },
        mobile: {
          value: "",
        },
      },
      sortLabel: "Latest Comment",
      sortComment: [
        {
          label: "Latest Comment",
          param: "",
        },
        {
          label: "Most Liked",
          param: "mostLiked",
        },
        {
          label: "Most Replied",
          param: "mostReplied",
        },
      ],
      swiperOptions: {
        video_terkait: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          watchSlidesVisibility: true,
          navigation: {
            prevEl: ".prev-video-terkait",
            nextEl: ".next-video-terkait",
          },
          breakpoints: {
            1023: {
              slidesPerView: 2.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
        etalase_product: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          watchSlidesVisibility: true,
          navigation: {
            prevEl: ".prev-etalase-product",
            nextEl: ".next-etalase-product",
          },
          breakpoints: {
            1023: {
              slidesPerView: 3,
              spaceBetween: 8,
            },
            320: {
              slidesPerView: 2.1,
              spaceBetween: 8,
            },
          },
        },
        ikuti_kelas: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-ikuti_kelas",
            nextEl: ".next-ikuti_kelas",
          },
          breakpoints: {
            1023: {
              slidesPerView: 2.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          },
        },
      },
    };
  },
  head() {
    return {
      ...this.headReturn,
      script: [
        { src: "//www.instagram.com/embed.js", async: true, defer: true },
        { src: "//platform.twitter.com/widgets.js", async: true, defer: true },
        { src: "//www.tiktok.com/embed.js", async: true, defer: true },
      ],
    };
  },
  jsonld() {
    if (!this.response.data) {
      return null;
    }

    let breadcrumbsSchema = this.response.data.breadcrumbs.map((item, i) => ({
      "@type": "ListItem",
      position: i + 1,
      name: item.name.toLowerCase(),
      item: `${config.BASE_URL}${
        item.type !== "interest"
          ? item.slug
          : this.response.data.breadcrumbs.length > 2
          ? `${this.response.data.breadcrumbs[1].slug}${item.slug}`
          : "/news"
      }`,
    }));

    breadcrumbsSchema.push({
      "@type": "ListItem",
      position: this.response.data.breadcrumbs.length + 1,
      name: this.response.data.title,
      item: `${config.BASE_URL}${this.$route.path}`,
    });

    return [
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: breadcrumbsSchema,
      },
      {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: this.response.data.title
          ? this.response.data.title.substring(0, 110)
          : "",
        image: [this.response.data.thumbnail.medium],
        datePublished: this.$moment(
          this.response.data.publishDate
        ).toISOString(),
        author: {
          "@type": "Person",
          name: this.response.data.createdBy
            ? this.response.data.createdBy
            : "Narasi Distribusi",
        },
        publisher: {
          "@type": "Organization",
          name: "Narasi TV",
          logo: {
            "@type": "ImageObject",
            url: "https://narasi.tv/logo.png",
          },
        },
      },
    ];
  },
  beforeRouteLeave(to, from, next) {
    this.modalLogin.hide();
    this.modalShare.hide();
    this.modalReportComment.hide();
    next();
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.modalLogin.hide();
      this.modalShare.hide();
      this.modalReportComment.hide();
      next(true);
    });
    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  },
  computed: {
    ...mapGetters({ headReturn: "seoWithAmp/headReturn" }),
    ...mapState({
      comments: (state) => {
        return state.videos.comments;
      },
      commentsMobile: (state) => {
        return state.videos.commentsMobile;
      },
      artikel_terkait: (state) => {
        return state.articles.artikel_terkait;
      },
      video_terkait: (state) => {
        return state.articles.video_terkait;
      },
      populer: (state) => {
        return state.videos.populer;
      },
      ikutiKelas: (state) => {
        return state.academies.academyClass;
      },
    }),
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    async reportComment(params) {
      let data = params;
      this.$axios
        .$post(`${config.CORE_SERVICE_API}comments/${data.commentId}/reports`, {
          type: data.type,
        })
        .then((response) => {
          this.modalReportComment.hide();
          this.$toast.success("Komentar berhasil dilaporkan");
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    toggleLike(comment, index, slug, commentType, indexSubComment = index) {
      if (this.$store.state.auth.loggedIn) {
        this.$store.commit("videos/SET_LIKE_DISLIKE_COMMENT", {
          comment: comment,
          indexComment: index,
          indexSubComment: indexSubComment,
          slug: slug,
          isLiked: comment.isLiked ? false : true,
          commentType: commentType,
        });
      } else {
        this.modalLogin.show();
      }
    },
    setCommentId(param) {
      this.comment_id = param;
    },
    async initData() {
      this.$gtm.push({
        event: "Event Program Analytics",
        event_category: this.response.data.channel.title,
        event_action: "Program Analytics Artikel",
        event_label: this.response.data.title,
        value: "Program " + this.response.data.channel.title,
        nama_program: this.response.data.channel.title,
        nonInteraction: true,
      });
      this.$gtm.push({
        event: "Event Interest Analytics",
        event_category: this.response.data.category.title,
        event_action: "Interest Content Video",
        event_label: this.response.data.title,
        value: "Interest " + this.response.data.category.title,
        nonInteraction: true,
      });
    },
    async postComment(type) {
      let data = {};
      switch (type) {
        case "main":
          data = {
            articleSlug: this.$route.params.slug,
            comment: this.comment,
          };
          break;
        case "sub":
          data = {
            articleSlug: this.$route.params.slug,
            comment: this.subComment.desktop.value,
            commentId: this.subComment.commentId,
          };
          break;
        case "subMobile":
          data = {
            articleSlug: this.$route.params.slug,
            comment: this.subComment.mobile.value,
            commentId: this.subComment.commentId,
          };
          break;
      }
      if (this.$store.state.auth.loggedIn) {
        if (data.comment) {
          await this.$axios
            .$post(
              data.commentId
                ? `${config.CORE_SERVICE_API}articles/${data.articleSlug}/comments/${data.commentId}`
                : `${config.CORE_SERVICE_API}articles/${data.articleSlug}/comments`,
              {
                comment: data.comment,
              }
            )
            .then((response) => {
              this.$store.dispatch("videos/getComments", {
                articleSlug: this.$route.params.slug,
                page: 1,
                limit:
                  this.screenSize <= 1024
                    ? this.limitCommentMobile
                    : this.limitCommentDesktop,
              });

              this.btnSend = false;
              this.comment = "";
              this.subComment.desktop.value = "";
              this.subComment.mobile.value = "";
              this.$toast.success("Komentar berhasil dikirim");
            })
            .catch((error) => {
              if (error.response.data.statusCode === 400) {
                this.$toast.error(
                  "Komentar Anda tidak terkirim karena melanggar UU ITE"
                );
              } else {
                this.$toast.error(
                  "Maaf, komentar anda tidak terkirim. Silahkan coba kembali"
                );
              }
            });
        } else {
          this.$toast.info("Komentar tidak boleh kosong");
        }
      } else {
        this.modalLogin.show();
      }
    },
    getSortCommentBy(param) {
      this.$store.dispatch("videos/getComments", {
        articleSlug: this.$route.params.slug,
        limit:
          this.screenSize < 1024
            ? this.limitCommentMobile
            : this.limitCommentDesktop,
        sort: param,
      });
    },
    nextArticle() {
      this.limit = this.limit + 5;
      this.$store.dispatch("videos/getVideoPopuler", {
        limit: this.limit,
      });
    },
    async loadMoreComments() {
      this.loadingShowMoreComment = true;
      this.limitCommentDesktop = this.limitCommentDesktop + 5;
      this.limitCommentMobile = this.limitCommentMobile + 2;
      await this.$store
        .dispatch("videos/getComments", {
          articleSlug: this.$route.params.slug,
          limit:
            this.screenSize < 1024
              ? this.limitCommentMobile
              : this.limitCommentDesktop,
        })
        .then(() => {
          this.loadingShowMoreComment = false;
        });
    },
    replyComment(i, commentId) {
      if (this.$store.state.auth.loggedIn) {
        if (i === this.subComment.indexComment) {
          this.subComment.indexComment = "";
        } else {
          this.subComment.indexComment = i;
        }
        if (commentId) {
          this.subComment.commentId = commentId;
        } else {
          this.subComment.commentId = "";
        }
      } else {
        this.modalLogin.show();
      }
    },
    getReplyText(childrenLength, index) {
      let string = "";
      if (childrenLength > 0) {
        string = "Lihat " + childrenLength + " Balasan Lainnya";
        if (
          childrenLength === this.comments.items[index].children.length &&
          index === this.subComment.indexShowSubComment
        ) {
          string = "Tutup " + childrenLength + " Balasan Lainnya";
        }
      }
      if (childrenLength == 1) {
        string = "Lihat Balasan Lainnya";
        if (
          childrenLength === this.comments.items[index].children.length &&
          index === this.subComment.indexShowSubComment
        ) {
          string = "Tutup Balasan Lainnya";
        }
      }
      return string;
    },
    showSubComment(i) {
      if (i === this.subComment.indexShowSubComment) {
        this.subComment.indexShowSubComment = "";
      } else {
        this.subComment.indexShowSubComment = i;
      }
    },
    setLink(param) {
      switch (param) {
        case "whatsapp":
          return (
            "https://wa.me/?text=" +
            this.url +
            "%3Futm_source=whatsapp%26utm_medium=share"
          );
        case "facebook":
          return (
            "https://www.facebook.com/sharer.php?u=" +
            this.url +
            "?utm_source=facebook&utm_medium=share"
          );
        case "twitter":
          return (
            "https://twitter.com/intent/tweet?url=" +
            this.url +
            "?utm_source=twitter&utm_medium=share"
          );
      }
    },
    copyLink() {
      navigator.clipboard.writeText(
        this.url + "?utm_source=copy_link&utm_medium=share"
      );
      this.$toast.success("Copy Link Success");
    },
    goto(id) {
      this.burger = true;
      const yOffset = -130;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
  async fetch() {
    await this.initData();
  },
  beforeMount() {
    this.$loadScript("https://js.rfp.fout.jp/rfp-infeed.js")
      .then(() => {
        let adsGPT = document.querySelector("#div-gpt-ad-1692873102878-0");
        let adsGPT2 = document.querySelector("#div-gpt-ad-1692873590923-0");
        let adsGPT3 = document.querySelector("#div-gpt-ad-1701941519832-0");
        if (adsGPT && adsGPT2 && adsGPT3) {
          let postGPT = document.querySelectorAll(
            ".desc-article p, .desc-article header, .desc-article div"
          );
          let x = 1;
          let x2 = 4;
          let x3 = 8;
          // let x2 = Math.floor(parseInt(postGPT.length / 2));
          // let x3 = parseInt(postGPT.length - 2);
          if (postGPT[x]) {
            if (postGPT[x].nodeName == "div" || postGPT[x].nodeName == "p") {
              postGPT[x].parentNode.insertBefore(adsGPT, postGPT[x]);
            } else {
              postGPT[x].parentNode.insertBefore(
                adsGPT,
                postGPT[x].nextSibling
              );
            }

            if (postGPT[x2]) {
              if (
                postGPT[x2].nodeName == "p" ||
                postGPT[x2].nodeName == "div"
              ) {
                postGPT[x2].parentNode.insertBefore(adsGPT2, postGPT[x2]);
              } else {
                postGPT[x2].parentNode.insertBefore(
                  adsGPT2,
                  postGPT[x2].nextSibling
                );
              }
            }

            if (postGPT[x3]) {
              if (
                postGPT[x3].nodeName == "p" ||
                postGPT[x2].nodeName == "div"
              ) {
                postGPT[x3].parentNode.insertBefore(adsGPT3, postGPT[x3]);
              } else {
                postGPT[x3].parentNode.insertBefore(
                  adsGPT3,
                  postGPT[x3].nextSibling
                );
              }
            }
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function () {
              var responsiveAdSlot = googletag
                .defineSlot(
                  "/22849909437/artikel-page-banner/artikel-in-article-1",
                  [
                    [300, 600],
                    [320, 480],
                    [320, 100],
                    [320, 50],
                    [300, 250],
                  ],
                  "div-gpt-ad-1692873102878-0"
                )
                .addService(googletag.pubads());
              var responsiveAdSlot2 = googletag
                .defineSlot(
                  "/22849909437/artikel-page-banner/artikel-in-article-2",
                  [
                    [320, 50],
                    [300, 250],
                    [300, 600],
                    [320, 480],
                    [320, 100],
                  ],
                  "div-gpt-ad-1692873590923-0"
                )
                .addService(googletag.pubads());
              var responsiveAdSlot3 = googletag
                .defineSlot(
                  "/22849909437/artikel-page-banner/artikel-in-article-3",
                  [
                    [320, 100],
                    [300, 600],
                    [320, 480],
                    [320, 50],
                    [300, 250],
                  ],
                  "div-gpt-ad-1701941519832-0"
                )
                .addService(googletag.pubads());

              var mapping = googletag
                .sizeMapping()
                .addSize([992, 0], [[300, 250]])
                .addSize(
                  [728, 0],
                  [
                    [728, 90],
                    [320, 480],
                    [320, 100],
                    [320, 50],
                    [300, 600],
                    [300, 250],
                    [1, 1],
                  ]
                )
                .addSize(
                  [320, 0],
                  [
                    [320, 480],
                    [320, 100],
                    [320, 50],
                    [300, 600],
                    [300, 250],
                    [1, 1],
                  ]
                )
                .addSize(
                  [0, 0],
                  [
                    [320, 50],
                    [1, 1],
                  ]
                )
                .build();

              responsiveAdSlot.defineSizeMapping(mapping);
              responsiveAdSlot2.defineSizeMapping(mapping);
              responsiveAdSlot3.defineSizeMapping(mapping);
              googletag.pubads().enableSingleRequest();
              googletag.pubads().collapseEmptyDivs();
              googletag.enableServices();
              googletag.pubads().enableLazyLoad();
              googletag.display("div-gpt-ad-1692873102878-0");
              googletag.display("div-gpt-ad-1692873590923-0");
              googletag.display("div-gpt-ad-1701941519832-0");
            });
          }
        }

        let ads = document.querySelector(".freakoutAds");
        if (ads) {
          let post = document.querySelectorAll(
            ".desc-article p, .desc-article div, .desc-article figure"
          );
          x = parseInt(post.length / 2);
          if (post[x]) {
            if (post[x].nodeName == "p") {
              post[x].parentNode.insertBefore(ads, post[x]);
            } else {
              post[x].parentNode.insertBefore(ads, post[x].nextSibling);
            }
          }
          RFP.InFeed.Default.run({ immediately: true });
        }
      })
      .catch(() => {
        console.log("failed");
      });
  },
  mounted() {
    this.$store.dispatch("videos/getComments", {
      articleSlug: this.$route.params.slug,
      limit:
        this.screenSize < 1024
          ? this.limitCommentMobile
          : this.limitCommentDesktop,
    });
    this.$store.dispatch("videos/getVideoPopuler", {
      limit: this.limit,
    });
    this.$store.dispatch("articles/getArtikelTerkait", this.response.data.id);
    this.$store.dispatch("articles/getVideoTerkait", this.response.data.id);
    this.$store.dispatch("academies/getClass");
    this.url = config.BASE_URL + this.$route.path;
    this.screenSize = window.screen.width;

    this.$loadScript("https://embed.typeform.com/next/embed.js")
      .then(() => {
        console.log("success");
      })
      .catch(() => {
        console.log("failed");
      });

    if (this.$route.query.page && this.response.data) {
      setTimeout(() => {
        let el = document.getElementById("part-2");
        el.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .gliaplayer-container {
  margin-bottom: 1rem !important;
}
::v-deep #div-gpt-ad-multibanner1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    iframe {
      background-color: #e3e3e3;
      max-height: none !important;
    }
  }
}
::v-deep .flourish-scatter .flourish-embed {
  iframe {
    max-height: none !important;
  }
}
::v-deep .flourish-embed-iframe {
  max-height: none !important;
}
::v-deep .insert-ads {
  .ats-slot {
    div {
      iframe {
        background-color: #e3e3e3;
        max-height: none !important;
      }
    }
  }
}
::v-deep #div-gpt-ad-1692873102878-0 {
  overflow: hidden;
  min-width: 300px;
  min-height: 300px;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100% !important;
    margin: 0 0 1rem 0 !important;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    @media only screen and (max-width: 1024px) {
      height: fit-content !important;
    }
    iframe {
      width: fit-content !important;
      margin-top: 8px 0 24px !important;
      @media only screen and (max-width: 1024px) {
        margin: 24px 0 !important;
      }
    }
  }
}
::v-deep #div-gpt-ad-1692873590923-0 {
  overflow: hidden;
  min-width: 300px;
  min-height: 300px;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100% !important;
    margin: 0 0 1rem 0 !important;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    @media only screen and (max-width: 1024px) {
      height: fit-content !important;
    }
    iframe {
      width: fit-content !important;
      margin-top: 8px 0 24px !important;
      @media only screen and (max-width: 1024px) {
        margin: 24px 0 !important;
      }
    }
  }
}
::v-deep #div-gpt-ad-1701941519832-0 {
  overflow: hidden;
  min-width: 300px;
  min-height: 300px;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100% !important;
    margin: 0 0 1rem 0 !important;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    @media only screen and (max-width: 1024px) {
      height: fit-content !important;
    }
    iframe {
      width: fit-content !important;
      margin-top: 8px 0 24px !important;
      @media only screen and (max-width: 1024px) {
        margin: 24px 0 !important;
      }
    }
  }
}
::v-deep .mobile-typeform {
  height: 35vw !important;
  @media only screen and (max-width: 1024px) {
    height: 130vw !important;
    max-height: unset !important;
    .tf-v1-widget,
    iframe {
      height: 130vw !important;
    }
  }
}

::v-deep #footer_sticky {
  overflow: hidden;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active {
  transition: all 0.5s;
}
.list-enter /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.dot-spin-wrapper {
  height: 40px;
  margin-top: 30px;
}
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}
.article {
  padding: 32px 0 48px;
  @media only screen and (max-width: 1024px) {
    padding-top: 0;
  }
  &.removePadding {
    padding: 0;
  }
  #modal-share {
    .modal-header {
      padding: 30px 30px 20px;
      border: none;
      .modal-title {
        font-size: 24px;
        font-weight: 500;
        color: #4a25aa;
      }
      .close {
        cursor: pointer;
        opacity: 1;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px 30px;
      .sosmed-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        svg {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }
        .sosmed {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          color: #828282;
        }
      }
    }
  }
  .container {
    // display: flex;
    // @media only screen and (max-width: 1024px) {
    //   // flex-direction: column;
    //   padding: 0;
    // }
    .primary {
      display: flex;
      width: 100%;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
      }
      .article-reguler {
        width: 70%;
        padding-right: 30px;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding: 0;
        }
        &__headline {
          @media only screen and (max-width: 1024px) {
            padding: 24px 0 0;
          }
          .breadcrumbs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #e3e3e3;
            width: 100%;
            padding: 0 0 10px 0;
            list-style: none;
            .breadcrumb-item {
              a {
                font-size: 12px;
                font-weight: 500;
                color: #616161 !important;
                text-transform: uppercase;
                @media only screen and (max-width: 1024px) {
                  text-transform: capitalize;
                }
              }
            }
          }
          .title_ {
            font-size: 32px;
            font-weight: 500;
            color: #0c0b0d;
            line-height: 37px;
            margin: 12px 0;
            @media only screen and (max-width: 1024px) {
              font-size: 22px;
              line-height: 27px;
              margin: 0 0 8px 0;
            }
          }
          .date-and-share {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            @media only screen and (max-width: 1024px) {
              margin-bottom: 14px;
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .date {
              .text {
                font-size: 14px;
                color: #616161;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 12px;
                }
              }
            }
            .share-wrapper {
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              .sosmed-wrapper {
                svg {
                  width: 32px;
                  height: 32px;
                  @media only screen and (max-width: 1024px) {
                    width: 30px;
                    height: 30px;
                  }
                }
                &.copy-link {
                  color: #1a1a1a;
                  font-size: 12px;
                  padding: 6px 12px;
                  border-radius: 50rem;
                  border: 1px solid #1a1a1a;
                }
              }
            }
          }
        }
        &__thumbnail {
          padding-bottom: 8px;
          border-bottom: 1px solid #e3e3e3;
          @media only screen and (max-width: 1024px) {
            padding-bottom: 14px;
          }
          .caption {
            margin: 8px 0 0;
            font-size: 12px;
            color: #999999;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
        &__detail {
          margin-bottom: 48px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 32px;
          }
          .penulis-editor {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px;
            margin: 8px 0 1rem;
            @media only screen and (max-width: 1024px) {
              margin: 14px 0 1rem;
            }
            ::v-deep .gliaplayer-container {
              order: 3;
              margin-bottom: 0 !important;
            }
            ::v-deep .div-gpt-ad-multibanner1 {
              order: 4;
            }
            .penulis {
              order: 1;
              position: relative;
              margin: 0;
              padding: 0;
              font-size: 14px;
              color: #616161;
              height: fit-content;
              @media only screen and (max-width: 1024px) {
              }
              span {
                font-weight: 500;
              }
            }
            .editor {
              order: 2;
              position: relative;
              margin: 0;
              padding: 0 0 0 16px;
              font-size: 14px;
              color: #616161;
              height: fit-content;
              @media only screen and (max-width: 1024px) {
              }
              span {
                font-weight: 500;
              }
              &:after {
                content: " ";
                position: absolute;
                border-left: 1px solid #616161;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: 60%;
                margin-top: auto;
                margin-bottom: auto;
                @media only screen and (max-width: 1024px) {
                  height: 55%;
                }
              }
            }
            div {
              width: 100%;
            }
          }
          ::v-deep .article-content {
            position: relative;
            .desc-article {
              overflow: hidden;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 24px;
              @media only screen and (max-width: 1024px) {
                margin-bottom: 20px;
              }
              header {
                margin-bottom: 1rem !important;
              }
              br {
                display: none;
              }
              img {
                width: 100%;
                height: auto;
                max-width: 100%;
              }
              figure {
                padding-top: 8px;
                @media only screen and (max-width: 1024px) {
                  padding-top: 4px;
                }
                img {
                  width: 100%;
                  height: auto;
                  max-width: 100%;
                }
              }
              iframe {
                @media only screen and (max-width: 1024px) {
                  max-width: 100%;
                  max-height: 56.25vw;
                }
                &.instagram-media {
                  @media only screen and (max-width: 1024px) {
                    padding-top: 4px;
                    max-width: 100%;
                    max-height: unset;
                  }
                }
              }
              table {
                overflow: scroll;
                width: 100%;
                display: block;
              }
              .gpt-ads {
                position: relative;
                margin: 0 auto 1rem !important;
                iframe {
                  background: #e3e3e3;
                  z-index: 1;
                  position: relative;
                  @media only screen and (max-width: 1024px) {
                    max-width: 100%;
                    max-height: unset;
                  }
                }
                &::before {
                  color: #999;
                  content: "Advertisement";
                  font-size: 12px;
                  left: 50%;
                  letter-spacing: 2px;
                  padding: 3px 0;
                  position: absolute;
                  top: 50%;
                  transform: translate3d(-50%, -50%, 0);
                  z-index: 2;
                }
              }
              span {
                img {
                  width: unset;
                  height: unset;
                }
              }
              a {
                img {
                  width: auto;
                }
              }
              h3 {
                padding-top: 8px;
                margin-bottom: 16px;
                @media only screen and (max-width: 1024px) {
                  padding-top: 4px;
                  margin-bottom: 14px;
                }
              }
              h4,
              h5 {
                margin-bottom: 12px;
                padding-top: 8px;
                @media only screen and (max-width: 1024px) {
                  padding-top: 4px;
                }
              }
              blockquote {
                position: relative;
                padding-left: 40px;
                margin-left: 40px;
                @media only screen and (max-width: 1024px) {
                  padding-left: 32px;
                  margin-left: 0;
                }
                p {
                  font-size: 16px;
                  color: #051c2c;
                  line-height: 32px;
                  @media only screen and (max-width: 1024px) {
                    line-height: 28px;
                  }
                }
                &:before {
                  content: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7148 0.910156V1.86719C15.3607 2.36198 14.2995 3.14323 13.5312 4.21094C12.763 5.26562 12.3789 6.36589 12.3789 7.51172C12.3789 7.75911 12.418 7.94141 12.4961 8.05859C12.5482 8.13672 12.6068 8.17578 12.6719 8.17578C12.737 8.17578 12.8346 8.13021 12.9648 8.03906C13.3815 7.73958 13.9089 7.58984 14.5469 7.58984C15.2891 7.58984 15.9466 7.89583 16.5195 8.50781C17.0924 9.10677 17.3789 9.82292 17.3789 10.6562C17.3789 11.5286 17.0469 12.2969 16.3828 12.9609C15.7318 13.625 14.9375 13.957 14 13.957C12.9062 13.957 11.9622 13.5078 11.168 12.6094C10.3737 11.7109 9.97656 10.5065 9.97656 8.99609C9.97656 7.23828 10.5169 5.66276 11.5977 4.26953C12.6784 2.8763 14.3841 1.75651 16.7148 0.910156ZM7.32031 0.910156V1.86719C5.96615 2.36198 4.90495 3.14323 4.13672 4.21094C3.36849 5.26562 2.98438 6.36589 2.98438 7.51172C2.98438 7.75911 3.02344 7.94141 3.10156 8.05859C3.15365 8.13672 3.21224 8.17578 3.27734 8.17578C3.34245 8.17578 3.4401 8.13021 3.57031 8.03906C3.98698 7.73958 4.51432 7.58984 5.15234 7.58984C5.89453 7.58984 6.55208 7.89583 7.125 8.50781C7.69792 9.10677 7.98438 9.82292 7.98438 10.6562C7.98438 11.5286 7.65234 12.2969 6.98828 12.9609C6.33724 13.625 5.54297 13.957 4.60547 13.957C3.51172 13.957 2.56771 13.5078 1.77344 12.6094C0.979167 11.7109 0.582031 10.5065 0.582031 8.99609C0.582031 7.23828 1.1224 5.66276 2.20312 4.26953C3.28385 2.8763 4.98958 1.75651 7.32031 0.910156Z' fill='black'/%3E%3C/svg%3E%0A");
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 60%;
                  margin-top: auto;
                  margin-bottom: auto;
                  @media only screen and (max-width: 1024px) {
                    height: 55%;
                  }
                }
              }
              .baca-juga-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-items: center;
                align-items: center;
                width: auto;
                margin-bottom: 1rem;
                gap: 16px;
                p {
                  display: inline-block;
                  font-size: 18px;
                  font-weight: 700;
                  color: #1a1a1a;
                  background-color: #e3e3e3;
                  line-height: 27px;
                  padding: 12px 16px;
                  margin: 0;
                  a {
                    font-size: 18px;
                    font-weight: 700;
                    color: #4a25aa;
                    line-height: 27px;
                    text-decoration: none;
                    padding-left: 8px;
                  }
                }
                .gliaplayer-container {
                  background-color: #f1f1f1;
                  margin-bottom: 0 !important;
                  padding-top: 16px !important;
                }
              }
              .baca-juga-wrapper-custom {
                display: flex;
                flex-wrap: wrap;
                justify-items: center;
                align-items: center;
                width: auto;
                margin-bottom: 1rem;
                gap: 16px;
                div {
                  display: inline-block;
                  background-color: #e3e3e3;
                  line-height: 27px;
                  padding: 12px 16px;
                  margin: 0;
                  span {
                    display: inline-block !important;
                    font-size: 18px !important;
                    font-weight: 700;
                    color: #1a1a1a;
                  }
                  a {
                    color: #4a25aa;
                    font-size: 18px !important;
                    font-weight: 700;
                    text-decoration: underline !important;
                  }
                }
                .gliaplayer-container {
                  background-color: #f1f1f1;
                  margin-bottom: 0 !important;
                  padding-top: 16px !important;
                }
              }
              .card-snippet {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                background: #ffffff;
                padding: 14px;
                margin-bottom: 16px;
                border: 1px solid #eaeaea;
                border-radius: 4px;

                @media only screen and (max-width: 1024px) {
                  flex-wrap: wrap;
                  gap: 14px;
                  justify-content: space-between;
                  padding: 12px;
                }
                .img-snippet {
                  width: auto;
                  order: 1;
                  @media only screen and (max-width: 1024px) {
                    width: 25%;
                  }
                }
                .copy-snippet {
                  width: 60%;
                  order: 2;
                  @media only screen and (max-width: 1024px) {
                    width: 70%;
                  }
                  .title-snippet {
                    font-size: 16px;
                    color: black;
                    line-height: 24px;
                    margin-bottom: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                  }
                  .price-snippet {
                    font-size: 16px;
                    font-weight: 700;
                    color: #4a25aa;
                    margin: 0;
                  }
                }
                .gpt-ads {
                  order: 4;
                }
                .btn-snippet {
                  background: #4a25aa;
                  font-size: 12px;
                  font-weight: 700;
                  white-space: nowrap;
                  color: #fafafa;
                  padding: 4px 16px;
                  border-radius: 4px;
                  order: 3;
                  width: 20%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  @media only screen and (max-width: 1024px) {
                    margin-left: auto;
                  }
                  &:hover {
                    color: #fafafa !important;
                  }
                }
              }
            }
            figure {
              padding-top: 8px;
              @media only screen and (max-width: 1024px) {
                padding-top: 4px;
              }
              img {
                width: 100%;
                height: auto;
                max-width: 100%;
              }
            }
            ::v-deep .frame {
              #img {
                width: 100%;
              }
            }

            .shadow-read-more {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100px;
              background: linear-gradient(
                180deg,
                rgba(241, 241, 241, 0) -100.96%,
                #f1f1f1 100%
              );
            }
          }
          .read-more-article {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 16px 0;
            margin-top: 24px;
            background: rgba(74, 37, 170, 0.2);
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            color: #4a25aa;
            cursor: pointer;
          }

          .etalase-product {
            padding: 16px;
            background: #dbd3ee;

            .list-etalase-product {
              position: relative;

              .shadow-arrow {
                // pointer-events: none;
                // position: absolute;
                // top: 0;
                // width: 100px;
                // height: 100%;
                // padding: 0;
                // background: rgb(0, 0, 0);
                // z-index: 2;
                // opacity: 1;
                // transition: ease-in-out 0.5s;
                // @media only screen and (max-width: 1024px) {
                //   display: none;
                // }

                svg {
                  pointer-events: visible;
                }
                &.shadow-left {
                  left: 0;
                  background: linear-gradient(
                    to left,
                    hsla(0, 0%, 100%, 0),
                    #f1f1f1
                  );
                }

                &.shadow-right {
                  right: 0;
                  background: linear-gradient(
                    to right,
                    hsla(0, 0%, 100%, 0),
                    #f1f1f1
                  );
                }

                &.swiper-button-disabled {
                  opacity: 0;
                  z-index: 0;
                }
              }

              .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                width: 40px;
                height: 40px;
                background: #ffffff;
                border-radius: 50rem;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
                @media only screen and (max-width: 1024px) {
                  display: none;
                }

                &.prev {
                  left: -2%;
                }

                &.next {
                  right: -2%;
                }

                &.hide {
                  display: none;
                }
              }

              .swiper-container {
                @media only screen and (max-width: 1024px) {
                  // padding: 20px !important;
                  // margin: -20px !important;
                }
                .swiper-wrapper {
                  height: 100% !important;
                }
              }
            }
          }
          .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            margin: 32px 0;
            .text {
              order: 1;
              margin: 0;
              font-size: 20px;
              color: #616161;
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
              }
            }
            .tag-wrapper {
              order: 2;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              align-items: center;
              row-gap: 0.5vw;
              column-gap: 8px;
              @media only screen and (max-width: 1024px) {
                row-gap: 2vw;
              }
              .tag {
                margin: 0;
                padding: 0.2vw 0.5vw;
                background: #e0e0e0;
                border-radius: 0.2vw;
                font-size: 16px;
                color: #616161;
                @media only screen and (max-width: 1024px) {
                  padding: 1vw 2.5vw;
                  border-radius: 1vw;
                  font-size: 14px;
                }
              }
            }
            ::v-deep .google-auto-placed {
              order: 3;
            }
          }
          .komentar-icons {
            cursor: pointer;
          }
        }
        &__artikel-terkait {
          margin-bottom: 48px;
          @media only screen and (max-width: 1024px) {
            margin: 32px 0;
            padding: 32px 0 0;
            border-top: 1px solid lightgray;
          }
          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .lihat-semua {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;

              &:hover {
                color: #4a25aa !important;
              }

              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }

          .list-card-artikel-terkait {
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            @media only screen and (max-width: 1024px) {
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 8px;
              grid-row-gap: 8px;
            }
            ::v-deep .card-two {
              width: fit-content;
              .image {
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        &__video-terkait {
          @media only screen and (max-width: 1024px) {
            margin: 0;
            padding: 32px 0 0;
            border-top: 1px solid lightgray;
          }
          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .lihat-semua {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;

              &:hover {
                color: #4a25aa !important;
              }

              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }

          .list-card-video-terkait {
            position: relative;
            overflow: hidden;

            .shadow-arrow {
              pointer-events: none;
              position: absolute;
              top: 0;
              width: 100px;
              height: 100%;
              padding: 0;
              background: rgb(0, 0, 0);
              z-index: 2;
              opacity: 1;
              transition: ease-in-out 0.5s;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              svg {
                pointer-events: visible;
              }
              &.shadow-left {
                left: 0;
                background: linear-gradient(
                  to left,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.shadow-right {
                right: 0;
                background: linear-gradient(
                  to right,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.swiper-button-disabled {
                opacity: 0;
                z-index: 0;
              }
            }

            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }

            .swiper-container {
              @media only screen and (max-width: 1024px) {
                padding: 20px !important;
                margin: -20px !important;
              }
              .swiper-wrapper {
                height: 100% !important;
              }
            }
          }
        }
        &__narasi-academy {
          margin-bottom: 48px;
          @media only screen and (max-width: 1024px) {
            margin: 0 0 32px;
            padding: 32px 0 0;
            border-top: 1px solid lightgray;
          }
          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .lihat-semua {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;

              &:hover {
                color: #4a25aa !important;
              }

              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }

          .list-card-narasi-academy {
            position: relative;
            overflow: hidden;

            .shadow-arrow {
              pointer-events: none;
              position: absolute;
              top: 0;
              width: 100px;
              height: 100%;
              padding: 0;
              background: rgb(0, 0, 0);
              z-index: 2;
              opacity: 1;
              transition: ease-in-out 0.5s;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              svg {
                pointer-events: visible;
              }
              &.shadow-left {
                left: 0;
                background: linear-gradient(
                  to left,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.shadow-right {
                right: 0;
                background: linear-gradient(
                  to right,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.swiper-button-disabled {
                opacity: 0;
                z-index: 0;
              }
            }

            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }

            .swiper-container {
              @media only screen and (max-width: 1024px) {
                padding: 20px !important;
                margin: -20px !important;
              }
              .swiper-wrapper {
                height: 100% !important;
              }
            }
          }
        }
      }
      .playlist-article-reguler {
        width: 30%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
        &__populer {
          @media only screen and (max-width: 1024px) {
            padding: 32px 0;
            border-top: 1px solid lightgray;
          }
          .header {
            margin-bottom: 24px;
          }
          .body {
            .list-enter-active {
              transition: all 1s;
            }
            .list-enter /* .list-leave-active below version 2.1.8 */ {
              opacity: 0;
            }
            .list-video-populer {
              margin-bottom: 24px;
              ::v-deep .card-eight {
                &:first-child {
                  padding-top: 0;
                }
              }
              .card-video-populer {
                width: 100%;
                &:nth-child(6) {
                  padding-top: 0;
                }
              }
            }
            .button-show-more {
              text-align: center;
              span {
                cursor: pointer;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a25aa;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 700;
                color: #4a25aa;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                  height: 40px;
                  &:hover {
                    color: #4a25aa !important;
                  }
                }
                @media only screen and (min-width: 1024px) {
                  &:hover {
                    color: #8166c8 !important;
                    border-color: #8166c8 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .secondary {
      display: flex;
      width: 100%;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
      }
      .article-reguler {
        width: 70%;
        padding-right: 30px;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding: 0;
        }
        &__comment {
          .title-wrapper {
            padding: 16px 0;
            border-top: 1px solid lightgray;
            border-bottom: 1px solid lightgray;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              padding: 16px 0;
            }

            .filter {
              cursor: pointer;
              // .arrow {
              //   @media only screen and (max-width: 1024px) {
              //     transform: rotate(180deg);
              //   }
              // }
              .dropdown-toggle {
                display: flex;
                align-items: center;
                &::after {
                  display: none;
                }
                .text {
                  margin: 0 20px 0 0;
                  font-size: 12px;
                  color: #4a25aa;
                  @media only screen and (max-width: 1024px) {
                    margin-right: 10px;
                  }
                }
                svg {
                  width: 12px;
                  height: 12px;
                }
              }
              .dropdown-menu {
                padding: 0;
                border-radius: 10px;
                overflow: hidden;
                li {
                  div {
                    font-size: 12px;
                    padding: 12px 15px;
                    &:hover {
                      background: #4a25aa;
                      color: white !important;
                    }
                  }
                }
              }
            }
          }
          .comments {
            .type-comment {
              height: fit-content;
              display: flex;
              justify-content: space-between;
              padding: 8px 0;
              &__profile-image {
                display: flex;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
              }
              &__input {
                width: calc(100% - 60px);
                align-self: center;
                textarea {
                  font-size: 16px;
                  color: #757575;
                  height: fit-content;
                  border-radius: 6px;
                  &::placeholder {
                    color: #c2c1c6;
                    box-shadow: none;
                  }
                }
                .disclaimer {
                  font-size: 10px;
                  color: #c2c1c6;
                  margin: 4px 0;
                }
                .btn-wrapper {
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 8px;
                  .btn-cancel {
                    font-size: 14px;
                    font-weight: 500;
                    color: #9e9e9e;
                    margin-right: 15px;
                    padding: 5px 0;
                    cursor: pointer;
                  }
                  .btn-send {
                    font-size: 14px;
                    font-weight: 500;
                    color: #4a25aa;
                    padding: 5px 0;
                    cursor: pointer;
                  }
                }
              }
              &__send {
                width: 25px;
                height: auto;
                align-self: center;
                cursor: pointer;
                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            &__no {
              margin: 24px auto;
              padding: 30px 50px;
              background: #e0e0e0;
              width: fit-content;
              max-width: 400px;
              border-radius: 10px;
              text-align: center;
              svg {
                width: 50px;
                height: 50px;
                margin-bottom: 20px;
              }
              .desc {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 8px;
              }
              .sub-desc {
                font-size: 12px;
                font-weight: 400;
                color: #616161;
                margin: 0;
              }
            }
            &__card {
              display: flex;
              padding: 16px 0;
              border-bottom: 1px solid lightgray;
              &:last-child {
                border-bottom: none;
              }
              &__profile-image {
                .profile-picture {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  overflow: hidden;
                }
                ::v-deep img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  overflow: hidden;
                }
              }
              &__detail {
                width: -webkit-fill-available;
                padding-left: 20px;
                .comment-header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 8px;
                  @media only screen and (max-width: 1024px) {
                    margin-bottom: 5px;
                  }
                  &__copy {
                    display: flex;
                    align-items: center;
                    &__username {
                      font-size: 14px;
                      font-weight: 700;
                      margin-right: 30px;
                      margin-bottom: 0;
                      @media only screen and (max-width: 1024px) {
                        font-size: 12px;
                        margin-right: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        max-width: 80px;
                      }
                    }
                    &__time {
                      font-size: 12px;
                      font-weight: 400;
                      color: #757575;
                      margin: 0;
                      @media only screen and (max-width: 1024px) {
                        font-size: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        max-width: 80px;
                      }
                    }
                  }
                  &__dots {
                    width: 20px;
                    height: auto;
                    cursor: pointer;
                    @media only screen and (max-width: 1024px) {
                      width: 15px;
                    }
                    svg {
                      width: 100%;
                      height: auto;
                    }
                  }
                }
                .comment-body {
                  @media only screen and (max-width: 1024px) {
                    margin-bottom: 8px;
                  }
                  &__desc {
                    font-size: 12px;
                    font-weight: 400;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -ms-word-break: break-all;
                    word-break: break-all;
                    word-break: break-word;
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    hyphens: auto;
                  }
                }
                .comment-footer {
                  display: flex;
                  align-items: center;
                  &.addMargin {
                    margin-bottom: 16px;
                  }
                  &__like {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    cursor: pointer;
                    svg {
                      width: 15px;
                      height: 15px;
                      margin-right: 10px;
                      @media only screen and (max-width: 1024px) {
                        width: 12px;
                        height: 12px;
                        margin-right: 8px;
                      }
                    }
                    .total-like {
                      font-size: 12px;
                      font-weight: 400;
                      color: #616161;
                      margin: 0;
                    }
                  }
                  &__reply {
                    cursor: pointer;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    svg {
                      width: 14px;
                      height: 14px;
                      margin-right: 6px;
                    }
                    .text {
                      font-size: 12px;
                      font-weight: 400;
                      color: #616161;
                      margin: 0;
                    }
                  }
                }
                .show-more-reply {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  svg {
                    width: 8px;
                    height: 8px;
                    margin-right: 6px;
                    transition: 0.3s ease;
                    &.rotate {
                      transform: rotate(180deg);
                    }
                  }
                  p {
                    font-size: 12px;
                    font-weight: 400;
                    color: #616161;
                    margin: 0;
                  }
                }
              }
            }
          }
          .show-more-comment {
            margin: 0 auto;
            font-size: 14px;
            font-weight: 500;
            color: #4a25aa;
            text-decoration: underline;
            display: flex;
            justify-content: center;
            margin-top: 24px;
            cursor: pointer;
            @media only screen and (max-width: 1024px) {
              padding-bottom: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
